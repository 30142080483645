<template>
  <div class="home">
    <div class="minehead">
      <div @click="toLogin" class="changePhone">切换账号</div>

      <img @click="toUserMes" class="headPic" src="../assets/headPic.png" />
      <div class="phoneNum">{{ userMes.phone || "尚未登录" }}</div>

      <!-- <div class="botVip clearfix">
				<a @click="getVipUrl('https://hfgo.wo.cn/pay?gId=1226')" href="javascript:;"><img class="fl" src="../assets/vipMinPic1.png"></a>
				<a @click="getVipUrl('https://hfgo.wo.cn/pay?gId=1227')" href="javascript:;"><img class="fr" src="../assets/vipMinPic2.png"></a>
			</div> -->
    </div>
    <img
     v-if="fulishow"
      @click="toequity"
      style="width: 94%; margin: 10px auto"
      src="../assets/banner2.png"
    />
    <div class="mineList">
      <div @click="mineVip" class="eachBlock" v-if="vipType != 0">
        <div class="eachIco">
          <img style="width: 0.44rem" src="../assets/mineIco1.png" />
        </div>
        <span class="eachTit">会员</span>
        <img class="arrow" src="../assets/arrow1.png" />
      </div>
      <!-- <div @click="toBookCard" class="eachBlock">
				<div class="eachIco"><img style="width: 0.44rem;" src="../assets/mineIco7.png"></div>
				<span class="eachTit">我的兑书卡</span>
				<img class="arrow" src="../assets/arrow1.png">
			</div> -->
      <div @click="toCar" class="eachBlock">
        <div class="eachIco">
          <img style="width: 0.45rem" src="../assets/mineIco2.png" />
        </div>
        <span class="eachTit">购物车</span>
        <img class="arrow" src="../assets/arrow1.png" />
      </div>
      <div @click="toMineOrder" class="eachBlock">
        <div class="eachIco">
          <img style="width: 0.36rem" src="../assets/mineIco3.png" />
        </div>
        <span class="eachTit">订单</span>
        <img class="arrow" src="../assets/arrow1.png" />
      </div>
      <div @click="toAddress" class="eachBlock">
        <div class="eachIco">
          <img style="width: 0.39rem" src="../assets/mineIco4.png" />
        </div>
        <span class="eachTit">地址</span>
        <img class="arrow" src="../assets/arrow1.png" />
      </div>
      <div v-if="isShowService" @click="toService" class="eachBlock">
        <div class="eachIco">
          <img style="width: 0.44rem" src="../assets/mineIco5.png" />
        </div>
        <span class="eachTit">客服</span>
        <img class="arrow" src="../assets/arrow1.png" />
      </div>
      <div @click="toCdKey" class="eachBlock" v-if="vipType != 0">
        <div class="eachIco">
          <img style="width: 0.64rem" src="../assets/mineIco6.png" />
        </div>
        <span class="eachTit">卡密兑换</span>
        <img class="arrow" src="../assets/arrow1.png" />
      </div>
      <div @click="tovipEquity" class="eachBlock" v-if="vipType != 0">
        <div class="eachIco">
          <img style="width: 0.5rem" src="../assets/mineIco8.png" />
        </div>
        <span class="eachTit">会员领取</span>
        <img class="arrow" src="../assets/arrow1.png" />
      </div>
    </div>

    <FootTab></FootTab>
  </div>
</template>

<script>
import FootTab from "@/components/FootTab.vue";
import wx from "weixin-js-sdk";
import VConsole from "../assets/js/vconsole.min.js";
export default {
  name: "home",
  components: {
    FootTab,
  },
  data() {
    return {
      title: "",
      vipType: "1",
      userMes: [],
      isShowService: false,
      fulishow:false,
    };
  },
  created() {
    if (window.location.href.indexOf("iread") == -1) {
      this.isShowService = true;
    }
    let url = new URL(window.location.href);
    let params = new URLSearchParams(url.search);
    let phone = params.get("phone");
    if (phone) {
      if (phone.length == 11) {
        this.http.avoidlogin({ phone: phone }).then((res) => {
          if (res.data.status == 1) {
            this.userMes = res.data.data;
            this.status = res.data.status;
            localStorage.setItem("userMes", JSON.stringify(res.data.data));
          }
        });
      }
    }
  },
  mounted() {
    const ua = navigator.userAgent.toLowerCase();

    // 微信小程序环境
    if (ua.match(/miniprogram/i)) {
      console.log("小程序");
      this.fulishow = true
    } else if (ua.match(/micromessenger/i)) {
      console.log("微信浏览器环境");
    } else {
      console.log("普通浏览器环境");
    }

    this.vipType = localStorage.getItem("vipType");
    let url = new URL(window.location.href);
    let params = new URLSearchParams(url.search);

    let token = params.get("token");
    let originUrl = params.get("originUrl");
    let AGRMNO = params.get("AGRMNO");
    if (AGRMNO != "") {
      if (originUrl != null) {
        window.location.href = originUrl;
      }
    }

    if (token != null) {
      localStorage.setItem("userMes", JSON.stringify({ user_token: token }));
    }

    // 获取用户信息
    this.http.getUserMes().then((res) => {
      if (res.data.status == 1) {
        if (token != null) {
          res.data.data.user_token = token;
          localStorage.setItem("userMes", JSON.stringify(res.data.data));
        }
        this.userMes = res.data.data;
        this.status = res.data.status;
      }
    });
  },
  methods: {
    toCar() {
      this.$router.push({ name: "car" });
    },
    tovipEquity() {
      if (this.userMes.phone) {
        this.$router.push({ name: "vipEquity" });
      } else {
        this.wxState();
      }
    },
    mineVip() {
      if (this.userMes.phone) {
        this.$router.push({ name: "mineVip" });
      } else {
        this.wxState();
      }
    },
    toMineOrder() {
      if (this.userMes.phone) {
        this.$router.push({ name: "mineOrder" });
      } else {
        this.wxState();
      }
    },
    toBookCard() {
      if (this.userMes.phone) {
        this.$router.push({ name: "bookCard" });
      } else {
        this.wxState();
      }
    },
    toAddress() {
      if (this.userMes.phone) {
        sessionStorage.setItem("fromPath", "");
        this.$router.push({ name: "address" });
      } else {
        this.wxState();
      }
    },
    toService() {
        window.location.href = `tel:400-888-8202`;
      // this.$router.push({ name: "service" });
    },
    toCdKey() {
      if (this.userMes.phone) {
        this.$router.push({ name: "cdKey" });
      } else {
        this.wxState();
      }
    },
    toUserMes() {
      if (this.userMes.phone) {
        this.$router.push({ name: "userMes" });
      } else {
        // sessionStorage.setItem("loginReturn",window.location.href)
        // this.$router.push({name: "login"})
        this.wxState();
      }
    },
    toequity() {
      this.$router.push({ name: "equity" });
    },
    toLogin() {
      if (this.userMes.phone) {
        // 退出登录
        this.http.loginOut().then((res) => {
          if (res.data.data) {
            window.location.href = res.data.data;
          } else {
            this.wxState();
          }
        });
      } else {
        this.wxState();
      }
    },
    getVipUrl(url) {
      if (!this.isLiantong) {
        return;
      }
      if (this.userMes.phone) {
        var that = this;
        // 获取vip地址
        this.http
          .getVipUrl({
            redirectUri: url,
          })
          .then((res) => {
            if (res.data.status == 1) {
              window.location.href = res.data.msg;
            } else {
              that.$message({
                message: res.data.msg,
                type: "error",
              });
            }
          });
      } else {
        this.wxState();
      }
    },
    wxState() {
      let that = this;
      let state = false; //是否嵌套在小程序里
      wx.miniProgram.getEnv(function (res) {
        if (res.miniprogram == true) {
          state = true;
        }
      });
      if (state == true) {
        // console.log("我在小程序中");
        wx.miniProgram.navigateTo({ url: "/pages/login/login" });
      } else {
        // console.log("我在网页中");
        sessionStorage.setItem("loginReturn", window.location.href);
        that.$router.push({ name: "login" });
      }
    },
  },
};
</script>

<style scoped lang="scss">
.home {
  background-color: #fff;
  min-height: 100vh;
  padding-bottom: 1.46rem;
  text-align: left;

  .minehead {
    height: 2.93rem;
    background-image: url(../assets/mineHeadBg.png);
    background-size: cover;
    position: relative;

    .changePhone {
      position: absolute;
      top: 0.27rem;
      right: 0.3rem;
      width: 1.27rem;
      height: 0.44rem;
      border-radius: 100px;
      border: 0.02rem solid #402307;
      line-height: 0.44rem;
      font-size: 0.24rem;
      color: #533915;
      text-align: center;
    }

    .headPic {
      width: 1.46rem;
      height: 1.46rem;
      margin: 0px auto;
      padding-top: 0.4rem;
      box-sizing: content-box;
    }

    .phoneNum {
      text-align: center;
      font-size: 0.32rem;
      color: #222222;
      margin-top: 0.28rem;
    }

    .botVip {
      height: 1.3rem;
      width: 100%;
      position: absolute;
      bottom: 0.13rem;
      left: 0;
    }

    .botVip img {
      width: 3.16rem;
      height: 100%;
    }
  }

  .mineList {
    padding: 0px 0.29rem 0px 0.24rem;

    .eachBlock {
      height: 1.09rem;
      line-height: 1.06rem;
      border-bottom: 2px solid #f6f6f6;
      position: relative;

      .eachIco {
        display: inline-block;
        vertical-align: middle;
        width: 0.78rem;

        img {
          display: block;
          margin: 0px auto;
        }
      }
      .eachTit {
        font-size: 0.32rem;
        color: #616161;
        display: inline-block;
        vertical-align: middle;
      }
      .arrow {
        position: absolute;
        right: 0.37rem;
        top: 0.42rem;
        width: 0.14rem;
        height: 0.24rem;
      }
    }
  }
}
</style>
