import { render, staticRenderFns } from "./mineVip.vue?vue&type=template&id=82e02d46&scoped=true"
import script from "./mineVip.vue?vue&type=script&lang=js"
export * from "./mineVip.vue?vue&type=script&lang=js"
import style0 from "./mineVip.vue?vue&type=style&index=0&id=82e02d46&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "82e02d46",
  null
  
)

export default component.exports