<template>
  <div style="background-color: #f84039">
    <img
      src="../assets/gszh.jpg"
      mode="widthFix"
      style="width: 100%"
    />
    <div class="benefit-page" v-if="lists.length != 0">
      <div style="height: 75px"></div>
      <div class="goods-name">
        目前已选择
        <span>【{{ lists[current].goodsName }}】</span>
      </div>
      <div>
        <div class="swiper-container">
          <div class="swiper-wrapper">
            <div class="swiper-slide" v-for="(item, index) of lists" :key="index">
              <img :src="item.productImg" mode="widthFix" style="width: 125px" />
            </div>
          </div>
        </div>
      </div>
      <div class="ipt phone">
        <input v-model="phone" type="text" placeholder="请输入订购的手机号码" />
      </div>
      <div class="ipt" v-if="lists[current].accountType === 2">
        <input v-model="qqcode" type="text" placeholder="请输入充值账号" />
      </div>
      <div class="ipt" v-if="lists[current].accountType === 3">
        <input v-model="qqcode" type="text" placeholder="请输入昵称" />
      </div>
      <div class="ipt code">
        <input v-model="code" type="text" placeholder="请输入验证码" />
        <div class="yanzheng" @click="vscodeClick">{{ vscode }}</div>
      </div>
      <div
        class="benefit-btn"
        :class="{
          activecss: lists[current].accountType === 2 || lists[current].accountType === 3,
        }"
      >
        <img
          mode="widthFix"
          src="https://cdn.img.haiov.com/bookshop/duihuan.jpg"
          @click="conversionClick"
        />
      </div>
    </div>
    <div class="benefit-rule">
        1.活动对象:已开通国搜智慧书城黄金会员的用户<br />
        2.活动时间:2025年1月1日-2026年12月31日<br />
        3、活动内容：<br />
        &nbsp; &nbsp;在活动期间，成功开通国搜智慧书城黄金会员的用户，<span style="color: #d93c22">可额外享受热门会员（月卡）{{ lists.length }}选1优惠</span>，开通当月仅限领取一次。<br />
        &nbsp; &nbsp;会员包含:汽水音乐、全民K歌、百度文库、美图秀秀、剪映、酷狗音乐、酷我音乐、网易云音乐、QQ音乐、懒人听书、喜马拉雅、Keep、夸克网盘超级会员、QQ超级会员、新浪微博、知乎盐选、百度网盘超级会员、WPS稻壳会员、饿了吗、趣配音等。<br />
        4.注意事项：<br />
        （1）权益未充值到账前退订本业务，视作放弃活动权益产品，将不再发放。<br />
        （2）如有疑问可致电客服热线：4008888202。<br />
    </div>

    <div style="height: 50px"></div>

    <!-- 兑换成功 -->
    <div v-if="totalyes" class="totalCss">
      <div class="totalCss_contentto">
        <div class="total_yas">权益兑换中...</div>
        <div style="position: relative">
          <img
            mode="widthFix"
            src="http://shop.oss.aizhishifm.com/zxs-shopc1068777ae92413d8e55bb0ff3b830bc.png"
            style="width: 100%"
          />
          <div class="total_yas_bottom">
            <img v-if="allimg" class="total_imgA" mode="widthFix" :src="lists[current].productImg" />
            <div style="margin-top: 15px;line-height: 20px;">
              您领取的<span style="color: #f77649">{{ lists[current].goodsName }}</span>
              将很快发放至您的手机号中，如有疑问请再次尝试或联系客服。
            </div>
            <img
              class="total_imgB"
              mode="widthFix"
              @click="totalyes = false"
              src="http://shop.oss.aizhishifm.com/zxs-shop083fe3405b1947ab9f284d9dcf487b9a.png"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Swiper from "swiper";
import "swiper/css/swiper.min.css";
let state = true;
const TIME_COUNT = 60;

export default {
  data() {
    return {
      lists: [],
      phone: "",
      qqcode: "",
      code: "",
      vscode: "获取验证码",
      timer: null,
      current: 1,
      vipname: {},
      allimg: "",
      totalyes: false,
      mySwiper: null,
    };
  },
  created() {
    this.rightsGet();
  },
  mounted() {
    this.initSwiper();
  },
  methods: {
    initSwiper() {
      let than = this;
      var mySwiper = new Swiper(".swiper-container", {
        slidesPerView: 3, // 一组三个
        initialSlide: 1,
        observer: true,
        centeredSlides: true,
        spaceBetween: 10, // 两边slides的间隔
      });

      mySwiper.on("slideChange", function (e) {
        than.current = mySwiper.activeIndex;
      });
    },
    intervalChange(e) {
      this.qqcode = "";
      this.vipname = this.lists[e.detail.current];
      this.current = e.detail.current;
      this.allimg = this.lists[e.detail.current].productImg;
    },
    rightsGet() {
      let than = this;
      axios
        .post("https://woread.diamond.clb.aizhishifm.cn/edu/goodsList/gousMWEBSign1990", {
          headers: {
            "content-type": "application/json",
          },
        })
        .then(function (res) {
          if (res.data.status === 1) {
            than.lists = res.data.data;
            than.vipname = res.data.data[1];
            than.allimg = res.data.data[1].productImg;
            than.$nextTick(() => {
              than.initSwiper();
            });
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    inputPhone(e) {
      this.phone = e.target.value;
    },
    inputQQ(e) {
      this.qqcode = e.target.value;
    },
    inputCode(e) {
      this.code = e.target.value;
    },
    vscodeClick() {
      let than = this;
      if (!/^1[3-9]\d{9}$/.test(this.phone)) {
        this.$message({
          showClose: true,
          message: "请输入正确的手机号",
          type: "error",
        });
        return;
      }
      if (state) {
        state = false;
        axios
          .post(
            "https://woread.diamond.clb.aizhishifm.cn/common/sms/" + this.phone + "/2",{
				msgHead:'国搜智慧书城'
			},
          )
          .then(function (res) {
            if (res.data.status == 1) {
              than.$message({
                showClose: true,
                message: "验证码已发送",
				type: "success",
              });
              if (!than.timer) {
                than.vscode = TIME_COUNT;
                than.timer = setInterval(() => {
                  if (than.vscode > 0 && than.vscode <= TIME_COUNT) {
                    than.vscode -= 1;
                  } else {
                    clearInterval(than.timer);
                    than.vscode = "获取验证码";
                    than.timer = null;
                    state = true;
                  }
                }, 1000);
              }
            } else {
              state = true;
              than.$message({
                showClose: true,
                message: res.data.msg,
                type: "error",
              });
            }
          })
          .catch(function (error) {});
      }
    },
    conversionClick() {
      let than = this;
      const data = {
        phone: this.phone,
        smsCode: this.code,
        accessId: "woread_equity",
        goodsId: this.lists[this.current]?.id,
        qq: this.qqcode,
      };
      if (!/^1[3-9]\d{9}$/.test(this.phone)) {
        this.$message({
          showClose: true,
          message: "请输入正确的手机号",
          type: "error",
        });
        return;
      }
      if (this.lists[this.current].accountType == 1) {
        data.account = this.phone;
      }
      if (this.lists[this.current].accountType == 2) {
        if (this.qqcode == "") {
          this.$message({
            showClose: true,
            message: "请输入您的qq号码",
            type: "error",
          });
          return;
        }
      }
      if (this.lists[this.current].accountType == 3) {
        if (this.qqcode == "") {
          this.$message({
            showClose: true,
            message: "请输入您的昵称",
            type: "error",
          });
          return;
        }
      }
      if (this.code.length !== 6) {
        than.$message({
          showClose: true,
          message: "请输入正确的验证码",
          type: "error",
        });
        return;
      }
      axios
        .post(
          "https://woread.diamond.clb.aizhishifm.cn/edu/phone/charge/sms",
          data
        )
        .then(function (res) {
          if (res.data.status == 1) {
			than.totalyes = true;
			than.code = ''
          } else {
            than.$message({
              showClose: true,
              message: res.data.msg,
              type: "error",
            });
          }
        })
        .catch(function (error) {});
    },
  },
  beforeDestroy() {
    clearInterval(this.timer);
    state = true;
  },
};
</script>

<style lang="scss" scoped>
image {
  width: 100%;
}
// .swiper-container .swiper-slide-active {
  //   transform: scale(1.1); /* 将活动的slide放大1.1倍 */
// }
.ipt {
  background-color: #fff;
  height: 52px;
  border-radius: 25.5px;
  padding-left: 30px;
  width: 84%;
  margin: 5px auto 0;
}

.ipt input {
  width: 100%;
  height: 100%;
}

.ipt.code {
  position: relative;
}

.benefit-btn {
  width: 267px;
  height: 65.5px;
  margin: auto;
  text-align: center;
  margin-top: 93px;
}

.benefit-btn image {
  width: 100%;
  height: 100%;
}

.goods-name {
  position: absolute;
  top: 26px;
  left: 41px;
  font-size: 12px;
  color: #563f15;
}

.goods-name text {
  color: #d24221;
}

.swiper {
  width: 100%;
  height: 150px !important;
  position: relative;
}

.icon {
  display: inline-block;
  position: absolute;
  top: 100px;
  z-index: 9999;
}

.icon.left {
  left: 0;
}

.icon.right {
  right: 0;
}

/* .swiper-item */
.swiper-item {
  height: 35%;
  transform: scale(0.8);
  text-align: center;
  transition: all 0.1s ease-out;
}

.swiper-item.active {
  transform: scale(1);
}

.activecss {
  margin-top: 36px;
}

.benefit-page {
  width: 95%;
  margin: auto;
  height: 500px;
  background-image: url(https://cdn.img.haiov.com/bookshop/backage.jpg);
  background-size: 100% 100%;
  /* background-position: center; */
  background-repeat: no-repeat;
  position: relative;
}

/* .benefit */
.benefit {
  width: 100%;
  min-height: 100vh;
  background-size: 100% 432.5px;
  background-repeat: no-repeat;
  padding-top: 436.5px;
}

.benefit-content {
  width: 364px;
  margin: 0 auto;
  padding: 65px 20px 140px;
  position: relative;
}

.benefit-rule {
  background-image: url(https://cdn.img.haiov.com/bookshop/rule.jpg);
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  width: 88%;
  font-size: 13px;
  color: #a67a17;
  padding: 50px 10px 15px;
  line-height: 30px;
  margin: 10px auto 0;
  text-align: left;
}

.yanzheng {
  position: absolute;
  right: 5%;
  top: 0px;
  bottom: 0;
  margin: auto;
  font-size: 12.5px;
  color: #f77244;
  z-index: 99999;
  line-height: 52px;
}

/* .totalCss */
.totalCss {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: #00000068;
  font-size: 15px;
  height: 100vh;
  display: flex;
  align-items: center;
  z-index: 99999999999;
}

.totalCss .totalCss_content {
  width: 45%;
  height: fit-content;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  background: #f7f8fa;
  border-radius: 5px;
  overflow: auto;
}

.totalCss .totalCss_contentto {
  height: 250px;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  border-radius: 5px;
}

.totalCss .totalCss_content_title {
  text-align: center;
  font-size: 17.5px;
  font-weight: bold;
  padding: 35px 0 25px 0;
}

.totalCss .totalCss_content_text {
  text-align: center;
  font-size: 14px;
}

.totalCss .totalCss_content_fixe {
  overflow: auto;
  height: 38px;
}

.totalCss .totalCss_content_image {
  margin: 10px auto 0;
  display: block;
  width: 75px;
  height: 75px;
}

.totalCss .totalCss_content_input {
  text-align: center;
}

.totalCss .totalCss_content_input view {
  position: relative;
}

.totalCss .totalCss_content_input input {
  width: 40%;
  height: 40px;
  margin: 5px auto;
  border: none;
  background: #ffffff;
  outline: none;
  padding-left: 20px;
  text-align: left;
  font-size: 15px;
}

.totalCss .total_yas {
  width: 100%;
  text-align: center;
  color: #fce0b3;
  font-size: 25px;
  text-shadow: 0px 0px 10px #fce0b3;
}

.totalCss .total_yas_bottom {
  width: 67%;
  text-align: center;
  color: #000000;
  font-size: 12.5px;
  position: absolute;
  top: 0px;
  left: 0;
  right: 0;
  margin: auto;
}

.totalCss .total_imgA {
  width: 65px;
  margin: 55px auto 0;
}

.totalCss .total_imgB {
  width: 35px;
  height: 35px;
  margin-top: 60px;
  margin:30px auto 0;
}
</style>
